var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Registration - step 4 of 5 ")
      ]),
      _c("gov-back-link", { attrs: { to: { name: "register-new-step3" } } }, [
        _vm._v(" Back ")
      ]),
      _c("user-account", { attrs: { form: _vm.form, errors: _vm.errors } }),
      _c(
        "gov-button",
        {
          attrs: {
            start: "",
            to: { name: "register-new-step5" },
            disabled: _vm.fieldsEmpty
          }
        },
        [_vm._v("\n    Next\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }